<template>
    <BT-Blade-Item
        bladeName="customer-invoice"
        :bladesData="bladesData"
        navigation="customer-invoices"
        :onPullSuccessAsync="pullInvoice"
        :scroll="false"
        title="Customer Invoice"
        :defaultBladeWidth="500">
        <template v-slot="{ item, data }">
            <v-row no-gutters>
                <v-col cols="12">
                    <BT-Field-Company
                        :id="item.buyerID"
                        showAction
                        :title="item.buyer.companyName">
                        <template v-slot:action>
                            <BT-Menu
                                hideHeader
                                icon="mdi-cog"
                                left
                                title="More Options">
                                <template v-slot>
                                    <v-subheader>Print</v-subheader>
                                    <BT-Print
                                        buttonClass=""
                                        :getFileName="invD => `Invoice #${invD.invoice.invoiceNumber}.pdf`"
                                        :item="item"
                                        :itemID="item.id"
                                        :onPullSuccessAsync="pullForPrint">
                                        <template v-slot:activator="{ openDialog }">
                                            <v-list-item @click="openDialog" dense>
                                                <v-list-item-icon>
                                                    <v-icon>mdi-printer</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-content>
                                                    <v-list-item-title>Print Invoice</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </template>
                                        <template v-slot="{ item, strategy }">
                                            <v-container v-if="item != null" class="pdf-page">
                                                <v-row>
                                                    <v-col cols="8">
                                                        <div style="height: 25px;" />
                                                        <v-img v-if="strategy != 'jspdf'" class="pdf-logo" :src="item.logoImageData" max-width="100px" max-height="100px" />
                                                        <v-list-item three-line>
                                                            <v-list-item-content>
                                                                <v-list-item-subtitle>BILLED BY</v-list-item-subtitle>
                                                                <v-list-item-title>
                                                                    <v-icon left small>mdi-account</v-icon>
                                                                    <strong>{{ $BlitzIt.auth.session.company.accountName }}</strong>
                                                                </v-list-item-title>
                                                                <v-list-item-subtitle v-if="$BlitzIt.auth.session.company.primaryEmail != null" >
                                                                    <v-icon left small>mdi-email</v-icon>
                                                                    {{ $BlitzIt.auth.session.company.primaryEmail }}
                                                                </v-list-item-subtitle>
                                                                <v-list-item-subtitle v-if="$BlitzIt.auth.session.company.phoneNumber != null">
                                                                    <v-icon left small>mdi-phone</v-icon>
                                                                    {{ $BlitzIt.auth.session.company.phoneNumber }}
                                                                </v-list-item-subtitle>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                        <v-list-item class="mt-5">
                                                            <v-list-item-content>
                                                                <v-list-item-subtitle>
                                                                    <v-icon left small>mdi-arrow-right-bottom</v-icon>
                                                                    BILLED TO
                                                                </v-list-item-subtitle>
                                                                <v-list-item-title>
                                                                    <v-icon left small>mdi-account</v-icon>
                                                                    <strong>{{ item.invoice.buyer.companyName }}</strong>
                                                                </v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </v-col>
                                                    <v-col cols="4">
                                                        <v-list-item>
                                                            <v-list-item-content>
                                                                <h2>Invoice</h2>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                        <v-list-item dense>
                                                            <v-list-item-content>
                                                                <v-list-item-subtitle>Invoice #</v-list-item-subtitle>
                                                                <v-list-item-title>{{ item.invoice.invoiceNumber }}</v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                        <v-list-item dense>
                                                            <v-list-item-content>
                                                                <v-list-item-subtitle>Customer Order #</v-list-item-subtitle>
                                                                <v-list-item-title>{{ item.invoice.customerOrderNumber }}</v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                        <v-list-item dense>
                                                            <v-list-item-content>
                                                                <v-list-item-subtitle>Purchase Order #</v-list-item-subtitle>
                                                                <v-list-item-title>{{ item.invoice.purchaseOrderNumber }}</v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                        <v-list-item dense>
                                                            <v-list-item-content>
                                                                <v-list-item-subtitle>Issued On</v-list-item-subtitle>
                                                                <v-list-item-title>{{ item.invoice.issuedOn | toShortDate }}</v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                        <v-list-item dense>
                                                            <v-list-item-content>
                                                                <v-list-item-subtitle>Due On</v-list-item-subtitle>
                                                                <v-list-item-title>{{ item.invoice.dueOn | toShortDate }}</v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </v-col>
                                                </v-row>

                                                <v-row class="my-3" dense style="border-bottom: 1px solid lightgray;">
                                                    <v-col cols="2">QTY</v-col>
                                                    <v-col cols="6">DESCRIPTION</v-col>
                                                    <v-col cols="2">UNIT PRICE</v-col>
                                                    <v-col cols="2" style="text-align: right;">TOTAL</v-col>
                                                </v-row>

                                                <v-row no-gutters v-for="(lineItem, index) in item.invoice.lineItems" :key="index" style="font-size: 13px;">
                                                    <v-col cols="2">{{ lineItem.quantity | toDisplayNumber }}</v-col>
                                                    <v-col cols="6">{{ lineItem.description }}</v-col>
                                                    <v-col cols="2">{{ lineItem.unitPrice | toCurrency }}</v-col>
                                                    <v-col cols="2" style="text-align: right;">{{ lineItem.quantity * lineItem.unitPrice | toCurrency }}</v-col>
                                                </v-row>
                                                
                                                <v-divider style="margin: 10px 0px;" />

                                                <v-row dense style="font-size: 14px;">
                                                    <v-col cols="9" style="text-align: right;">Subtotal:</v-col>
                                                    <v-col cols="3" style="text-align: right;">{{ item.invoice.subTotal | toCurrency }}</v-col>
                                                </v-row>
                                                <v-row dense style="font-size: 14px;">
                                                    <v-col cols="9" style="text-align: right;">GST:</v-col>
                                                    <v-col cols="3" style="text-align: right;">{{ item.invoice.taxTotal | toCurrency }}</v-col>
                                                </v-row>
                                                
                                                <v-divider style="margin: 10px 0px;" />
                                                
                                                <v-row>
                                                    <v-col cols="9" style="text-align: right;">Total:</v-col>
                                                    <v-col cols="3" style="text-align: right;">
                                                        <h4>{{ item.invoice.amountTotal | toCurrency }}</h4>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </template>
                                    </BT-Print>
                                    <BT-Print
                                        buttonClass=""
                                        :getFileName="invD => `POD #${invD.invoice.invoiceNumber}.pdf`"
                                        :item="item"
                                        :itemID="item.id"
                                        :onPullSuccessAsync="pullForProof">
                                        <template v-slot:activator="{ openDialog }">
                                            <v-list-item @click="openDialog" dense>
                                                <v-list-item-icon>
                                                    <v-icon>mdi-printer</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-content>
                                                    <v-list-item-title>Print Proof Of Delivery</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </template>
                                        <template v-slot="{ item, strategy }">
                                            <v-container v-if="item != null" class="pdf-page">
                                                <v-row>
                                                    <v-col cols="8">
                                                        <div style="height: 25px;" />
                                                        <v-img v-if="strategy != 'jspdf'" class="pdf-logo" :src="item.logoImageData" max-width="100px" max-height="100px" />
                                                        <v-list-item three-line dense>
                                                            <v-list-item-content>
                                                                <v-list-item-subtitle>BILLED BY</v-list-item-subtitle>
                                                                <v-list-item-title>
                                                                    <v-icon left small>mdi-account</v-icon>
                                                                    <strong>{{ $BlitzIt.auth.session.company.accountName }}</strong>
                                                                </v-list-item-title>
                                                                <v-list-item-subtitle v-if="$BlitzIt.auth.session.company.primaryEmail != null" >
                                                                    <v-icon left small>mdi-email</v-icon>
                                                                    {{ $BlitzIt.auth.session.company.primaryEmail }}
                                                                </v-list-item-subtitle>
                                                                <v-list-item-subtitle v-if="$BlitzIt.auth.session.company.phoneNumber != null">
                                                                    <v-icon left small>mdi-phone</v-icon>
                                                                    {{ $BlitzIt.auth.session.company.phoneNumber }}
                                                                </v-list-item-subtitle>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                        <v-list-item dense>
                                                            <v-list-item-content>
                                                                <v-list-item-subtitle>
                                                                    <v-icon left small>mdi-arrow-right-bottom</v-icon>
                                                                    BILLED TO
                                                                </v-list-item-subtitle>
                                                                <v-list-item-title>
                                                                    <v-icon left small>mdi-account</v-icon>
                                                                    <strong>{{ item.invoice.buyer.companyName }}</strong>
                                                                </v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                        <div v-if="item.delivery != null" class="text-caption">
                                                            <span class="mr-5">Signed By: {{ item.delivery.receiverName }}</span>
                                                            <span class="mx-5">Temp: {{ item.delivery.tempOnDelivery }}</span>
                                                        </div>
                                                        <BT-Signature
                                                            v-if="item.delivery != null"
                                                            color="black"
                                                            height="150px"
                                                            :value="item.delivery.proofOfDelivery"
                                                            backgroundColor="white" />
                                                    </v-col>
                                                    <v-col cols="4">
                                                        <v-list-item dense>
                                                            <v-list-item-content>
                                                                <h2>Proof Of Delivery</h2>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                        <v-list-item dense>
                                                            <v-list-item-content>
                                                                <v-list-item-subtitle>Invoice #</v-list-item-subtitle>
                                                                <v-list-item-title>{{ item.invoice.invoiceNumber }}</v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                        <v-list-item dense>
                                                            <v-list-item-content>
                                                                <v-list-item-subtitle>Customer Order #</v-list-item-subtitle>
                                                                <v-list-item-title>{{ item.invoice.customerOrderNumber }}</v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                        <v-list-item dense>
                                                            <v-list-item-content>
                                                                <v-list-item-subtitle>Purchase Order #</v-list-item-subtitle>
                                                                <v-list-item-title>{{ item.invoice.purchaseOrderNumber }}</v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                        <v-list-item dense>
                                                            <v-list-item-content>
                                                                <v-list-item-subtitle>Issued On</v-list-item-subtitle>
                                                                <v-list-item-title>{{ item.invoice.issuedOn | toShortDate }}</v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                        <v-list-item dense>
                                                            <v-list-item-content>
                                                                <v-list-item-subtitle>Due On</v-list-item-subtitle>
                                                                <v-list-item-title>{{ item.invoice.dueOn | toShortDate }}</v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                        <BT-Entity
                                                            v-if="item.delivery != null && item.delivery.isPickup"
                                                            :itemValue="item.delivery.departureLocationID"
                                                            navigation="public-locations"
                                                            single
                                                            useLocalCache>
                                                            <template #default="{ item }">
                                                                <BT-Field-Address
                                                                    label="Pickup Up At"
                                                                    :value="item" />
                                                            </template>
                                                        </BT-Entity>
                                                        <BT-Entity
                                                            v-else-if="item.delivery != null && !item.delivery.isPickup"
                                                            :itemValue="item.delivery.destinationLocationID"
                                                            navigation="public-locations"
                                                            single
                                                            useLocalCache>
                                                            <template #default="{ item }">
                                                                <BT-Field-Address
                                                                    label="Delivered To"
                                                                    :value="item" />
                                                            </template>
                                                        </BT-Entity>
                                                        <v-list-item v-if="item.delivery != null && !item.delivery.isPickup" dense>
                                                            <v-list-item-content>
                                                                <v-list-item-title>Delivered On</v-list-item-title>
                                                                <v-list-item-subtitle>{{ item.delivery.deliveredOn | toShortDateAndTime }}</v-list-item-subtitle>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                        <v-list-item v-else-if="item.delivery != null && item.delivery.isPickup" dense>
                                                            <v-list-item-content>
                                                                <v-list-item-title>Picked Up On</v-list-item-title>
                                                                <v-list-item-subtitle>{{ item.delivery.releasedOn | toShortDateAndTime }}</v-list-item-subtitle>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                        <v-list-item v-if="item.delivery != null && !item.delivery.isPickup" dense>
                                                            <v-list-item-content>
                                                                <v-list-item-title>Delivered By</v-list-item-title>
                                                                <v-list-item-subtitle>
                                                                    <BT-Entity
                                                                        alternateText="Unknown"
                                                                        navigation="users"
                                                                        single
                                                                        :itemValue="item.delivery.deliveredByUserID"
                                                                        itemText="userName" />
                                                                </v-list-item-subtitle>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </v-col>
                                                </v-row>

                                                <v-row class="my-3" dense style="border-bottom: 1px solid lightgray;">
                                                    <v-col cols="2">QTY</v-col>
                                                    <v-col cols="6">Description</v-col>
                                                    <v-col cols="2">Unit Price</v-col>
                                                    <v-col cols="2" style="text-align: right;">Total</v-col>
                                                </v-row>

                                                <v-row no-gutters v-for="(lineItem, index) in item.invoice.lineItems" :key="index"
                                                    style="font-size: 12px;">
                                                    <v-col cols="2">{{ lineItem.quantity | toDisplayNumber }}</v-col>
                                                    <v-col cols="6">{{ lineItem.description }}</v-col>
                                                    <v-col cols="2">{{ lineItem.unitPrice | toCurrency }}</v-col>
                                                    <v-col cols="2" style="text-align: right;">{{ lineItem.quantity * lineItem.unitPrice | toCurrency }}</v-col>
                                                </v-row>
                                                
                                                <v-divider style="margin: 10px 0px;" />

                                                <v-row dense style="font-size: 14px;">
                                                    <v-col cols="9" style="text-align: right;">Subtotal:</v-col>
                                                    <v-col cols="3" style="text-align: right;">{{ item.invoice.subTotal | toCurrency }}</v-col>
                                                </v-row>
                                                <v-row dense style="font-size: 14px;">
                                                    <v-col cols="9" style="text-align: right;">GST:</v-col>
                                                    <v-col cols="3" style="text-align: right;">{{ item.invoice.taxTotal | toCurrency }}</v-col>
                                                </v-row>
                                                
                                                <v-divider style="margin: 10px 0px;" />
                                                
                                                <v-row>
                                                    <v-col cols="9" style="text-align: right;">Total:</v-col>
                                                    <v-col cols="3" style="text-align: right;">
                                                        <h4>{{ item.invoice.amountTotal | toCurrency }}</h4>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </template>
                                    </BT-Print>
                                </template>
                            </BT-Menu>
                        </template>
                    </BT-Field-Company>
                </v-col>

                <v-col cols="6" sm="4">
                    <BT-Field-String
                        v-model="item.invoiceNumber"
                        label="Invoice #"
                        :isEditing="data.isEditing" />
                </v-col>

                <v-col cols="6" sm="4">
                    <BT-Field-String
                        v-model="item.customerOrderNumber"
                        label="Customer Order #"
                        :isEditing="data.isEditing" />
                </v-col>

                <v-col cols="6" sm="4">
                    <BT-Field-String
                        v-model="item.purchaseOrderNumber"
                        label="Purchase Order #"
                        :isEditing="data.isEditing" />
                </v-col>

                <v-col cols="6" sm="4">
                    <BT-Field-Date
                        v-model="item.issuedOn"
                        label="Issued On"
                        :isEditing="data.isEditing" />
                </v-col>

                <v-col cols="6" sm="4">
                    <BT-Field-Date
                        v-model="item.dueOn"
                        label="Due On"
                        :isEditing="data.isEditing" />
                </v-col>

                <v-col cols="6" sm="4">
                    <BT-Field-Date
                        v-if="item.isPaid"
                        v-model="item.paidOn"
                        label="Paid On"
                        :isEditing="data.isEditing" />
                    <v-list-item v-else>
                        <v-list-item-content>
                            <v-list-item-subtitle class="error--text">*Unpaid*</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>

                <v-col cols="12">
                    <v-divider class="my-1" />
                    <v-subheader>Line Items
                        <v-spacer />
                        <BT-Sidebar-External-Links
                            :block="false"
                            buttonClass="mx-2 primary"
                            inline
                            :itemID="item.id"
                            label="Integrate"
                            navigation="customer-invoice"
                            small
                            syncNavigation="customer-invoice-syncing" />
                    </v-subheader>
                    <BT-Table
                        :canRefresh="false"
                        :canSelect="false"
                        :headers="[
                            { text: 'QTY', value: 'quantity', textFilter: 'toDisplayNumber', subtitle: 2, display: true },
                            { text: 'Description', value: 'description', subtitle: 1, display: true },
                            { text: 'Unit Price', value: 'unitPrice', textFilter: 'toCurrency', display: true },
                            { text: 'Total', value: 'total', display: true }]"
                        hideActions
                        :items="item.lineItems"
                        showTable>
                        <template v-slot:quantity="{ item }">
                            <v-edit-dialog class="text-right">
                                <span>{{ item.quantity | toDisplayNumber }} <span class="grey--text">/ {{ item.onOrder | toDisplayNumber }}</span></span>
                                <template v-slot:input>
                                    <v-text-field
                                        :disabled="!data.isEditing"
                                        v-model.number="item.quantity"
                                        single-line
                                        type="number" />
                                </template>
                            </v-edit-dialog>
                        </template>
                        <template v-slot:description="{ item }">
                            <v-edit-dialog class="text-right">
                                <span>{{ item.description }}</span>
                                <template v-slot:input>
                                    <v-text-field
                                        :disabled="!data.isEditing"
                                        v-model.number="item.description"
                                        label="Description"
                                        single-line />
                                </template>
                            </v-edit-dialog>
                        </template>
                        <template v-slot:unitPrice="{ item }">
                            <v-edit-dialog class="text-right">
                                <span>{{ item.unitPrice | toCurrency }}</span>
                                <template v-slot:input>
                                    <v-text-field
                                        :disabled="!data.isEditing"
                                        v-model.number="item.unitPrice"
                                        label="Unit Price"
                                        single-line
                                        class="text-right"
                                        prefix="$"
                                        type="number" />
                                </template>
                            </v-edit-dialog>
                        </template>
                        <template v-slot:total="{ item }">
                            <span>{{ (item.quantity * item.unitPrice) | toCurrency }}</span>
                        </template>
                        <!-- <template v-slot:listItem="{ item }">
                            <BT-List-Item-Avatar v-model="item.itemID" icon="mdi-cube-outline" />
                            
                        </template> -->
                    </BT-Table>
                </v-col>

                <v-col cols="12">
                    
                    <v-divider class="my-1" />

                    <v-row no-gutters>
                        <v-spacer />
                        <div>
                            <h4 class="text-right ma-3">Subtotal: {{ item.subTotal | toCurrency }}</h4>
                            <h4 class="text-right mx-3 mb-1">GST: {{ item.taxTotal | toCurrency }}</h4>
                        </div>
                    </v-row>
                    
                    
                    <v-divider />
                    
                    <v-row no-gutters>
                        <v-spacer />
                        <div class="d-flex align-center justify-center">
                            <BT-Single-Action-Dialog
                                v-if="item.amountPaid != item.amountTotal"
                                buttonClass="primary"
                                label="Amount Paid"
                                text="Paid"
                                icon="mdi-credit-card-outline"
                                width="300"
                                okText="Paid"
                                :item="amountPaid"
                                @ok="paid(item)"
                                :isDone="item.amountPaid == item.amountTotal">
                                <template slot-scope="{ item }">
                                    <v-text-field 
                                        label="Amount"
                                        type="number"
                                        v-model.number="item.v"
                                        prefix="$" />
                                </template>
                            </BT-Single-Action-Dialog>
                            <v-btn v-else class="mx-1" @click="item.amountPaid = item.amountTotal">
                                Fully Paid
                            </v-btn>
                        </div>
                        <div>
                            <h3 class="text-right ma-3">Total: {{ item.amountTotal | toCurrency }}</h3>
                            <h2 v-if="!item.isPaid && item.amountPaid > 0" class="text-right error--text ma-5">Amount Paid: {{ item.amountPaid | toCurrency }}</h2>
                            <h2 v-if="!item.isPaid" class="text-right warning--text ma-5">
                                Amount Due: {{ item.amountTotal - item.amountPaid | toCurrency }}
                            </h2>
                        </div>
                    </v-row>
                </v-col>

                <v-col cols="12">
                    <BT-Settings-Slider
                        :options="[
                            { title: 'User Activity', value: 'user-activity' },
                            { title: 'Delivered Stock', value: 'stock' },
                            { title: 'Delivered Packages', value: 'packages' },
                            { title: 'Courier Orders', value: 'courier-orders' },
                            { title: 'Delivery Information', value: 'delivery' }]">
                        <template #user-activity>
                            <BT-Blade-Items
                                actualHeight="100%"
                                :canExportCSV="false"
                                :canSelect="false"
                                hideActions
                                hideBladeHeader
                                hideHeader
                                navigation="ordering-user-activities"
                                :params="{ searchString: item.customerOrderNumber }"
                                showList>
                                <template v-slot:listItem="{ item }">
                                    <v-list-item-content>
                                        <v-list-item-subtitle>
                                            <span>
                                                <BT-Entity 
                                                    alternateText="(System) " 
                                                    inline 
                                                    navigation="users" 
                                                    itemText="userName" 
                                                    :itemValue="item.userID" />
                                            </span>
                                            <span>{{ item.notes }} on {{ item.createdOn | toLongDateAndTime }}</span>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </template>
                            </BT-Blade-Items>
                        </template>
                        <template #stock>
                            <BT-Blade-Items
                                actualHeight="100%"
                                :canExportCSV="false"
                                :canSelect="false"
                                :headers="[
                                    { text: 'QTY', value: 'quantity', textFilter: 'toDisplayNumber', isIcon: true },
                                    { text: 'Batch', value: 'batch.batchcode' },
                                    { text: 'DOM', value: 'batch.dom', textFilter: 'toShortDate', subtitle: true, prefix: 'DOM: ' },
                                    { text: 'EXP', value: 'batch.exp', textFilter: 'toShortDate', subtitle: true, prefix: 'EXP: ' },
                                    { text: 'Product', value: 'productID', display: true, title: true }]"
                                hideActions
                                hideBladeHeader
                                hideHeader
                                isSingle
                                :itemID="item.id"
                                navigation="transfer-archives"
                                :onPullSuccessAsync="res => { return res.transferOrderItems }">
                                <template v-slot:productID="{ item }">
                                    <BT-Entity
                                        navigation="products"
                                        alternateText="Not Found"
                                        itemText="productName"
                                        :itemValue="item.productID" />
                                </template>
                            </BT-Blade-Items>
                        </template>
                        <template #packages>
                            <BT-Blade-Items
                                actualHeight="100%"
                                :canExportCSV="false"
                                :canSelect="false"
                                :headers="[
                                    { text: 'QTY', value: 'quantity', textFilter: 'toDisplayNumber' },
                                    { text: 'Package', value: 'measurement', display: true }]"
                                hideActions
                                hideBladeHeader
                                hideHeader
                                isSingle
                                :itemID="item.id"
                                navigation="transfer-archives"
                                :onPullSuccessAsync="res => { return res.packagedItems }"
                                showTable>
                                <template v-slot:measurement="{ item }">
                                    <BT-Entity 
                                        navigation="measurements"
                                        :itemValue="item.measurementID"
                                        itemText="measurementName" />
                                </template>
                            </BT-Blade-Items>
                        </template>
                        <template #courier-orders>
                            <BT-Blade-Items
                                actualHeight="100%"
                                addBladeName="courier-order-archive"
                                :canExportCSV="false"
                                hideActions
                                hideBladeHeader
                                hideHeader
                                navigation="courier-order-archives"
                                :onPullSuccessAsync="pullCourierOrders"
                                :params="{ includeDetails: false, consignmentID: item.id }"
                                showList>
                                <template v-slot:list="{ items }">
                                    <v-list class="overflow-y-auto">
                                        <v-card v-for="(item, ind) in items" :key="ind" :to="{ name: 'courier-order-archive', params: { id: item.id }}">
                                            <v-row no-gutters>
                                                <v-col cols="12">
                                                    <BT-Field-Company :id="item.sellerID" />
                                                </v-col>
                                                <v-col cols="6" sm="4">
                                                    <BT-Entity 
                                                        navigation="public-locations"
                                                        :itemValue="item.departureLocationID"
                                                        single
                                                        useLocalCache>
                                                        <template v-slot="{ item }">
                                                            <BT-Field-Address
                                                                label="FROM"
                                                                :value="item" />
                                                        </template>
                                                    </BT-Entity>
                                                </v-col>

                                                <v-col cols="6" sm="4">
                                                    <BT-Entity 
                                                        navigation="public-locations"
                                                        :itemValue="item.destinationLocationID"
                                                        single
                                                        useLocalCache>
                                                        <template v-slot="{ item }">
                                                            <BT-Field-Address
                                                                label="TO"
                                                                :value="item" />
                                                        </template>
                                                    </BT-Entity>
                                                </v-col>
                                                
                                                <v-col cols="6" sm="4">
                                                    <BT-Field-String
                                                        v-model="item.courierOrderNumber"
                                                        label="Courier Order #" />
                                                </v-col>

                                                <v-col cols="6" sm="4">
                                                    <BT-Field-Date
                                                        v-model="item.dueDate"
                                                        label="Due On" />
                                                </v-col>

                                                <v-col cols="6" sm="4">
                                                    <BT-Field-Date
                                                        v-model="item.fulfilledOn"
                                                        label="Fulfilled On"
                                                        toShortDateAndTime />
                                                </v-col>

                                            </v-row>
                                        </v-card>
                                    </v-list>
                                </template>
                            </BT-Blade-Items>
                        </template>
                        <template #delivery>
                            <BT-Blade-Items
                                actualHeight="100%"
                                addBladeName="delivery-archive"
                                :canExportCSV="false"
                                customURL="/get/ByConsignmentID"
                                hideActions
                                hideBladeHeader
                                hideHeader
                                navigation="delivery-archives"
                                :onPullSuccessAsync="pullDeliveries"
                                :params="{ includeDetails: false, id: item.id }"
                                showList>
                                <template v-slot:list="{ items }">
                                    <v-list class="overflow-y-auto">
                                        <v-card v-for="(item, ind) in items" :key="ind" :to="{ name: 'delivery-archive', params: { id: item.id }}">
                                            <v-row no-gutters>
                                                <v-col cols="6" sm="4">
                                                    <BT-Entity 
                                                        navigation="public-locations"
                                                        :itemValue="item.departureLocationID"
                                                        single
                                                        useLocalCache>
                                                        <template v-slot="{ item }">
                                                            <BT-Field-Address
                                                                label="FROM"
                                                                :value="item" />
                                                        </template>
                                                    </BT-Entity>
                                                </v-col>

                                                <v-col cols="6" sm="4">
                                                    <BT-Entity 
                                                        navigation="public-locations"
                                                        :itemValue="item.destinationLocationID"
                                                        single
                                                        useLocalCache>
                                                        <template v-slot="{ item }">
                                                            <BT-Field-Address
                                                                label="TO"
                                                                :value="item" />
                                                        </template>
                                                    </BT-Entity>
                                                </v-col>

                                                <v-col cols="6" sm="4" class="d-flex align-center">
                                                    <div class="warning--text ml-4">
                                                        <span v-if="!item.isPickup && item.isAutoDeliver">**Auto Pick Up</span>
                                                        <span v-else-if="item.deliveredByUserID == null && item.deliveredOn != null">**Auto Deliver</span>
                                                        <span v-else-if="item.isPickup">**Pick Up</span>
                                                        <span v-else>**Deliver</span>
                                                    </div>
                                                </v-col>
                                                
                                                <v-col cols="12" sm="4">
                                                    <v-divider class="my-2" />
                                                    <v-subheader>
                                                        <v-icon left small>mdi-draw</v-icon>
                                                        Proof Of Release
                                                    </v-subheader>
                                                    <BT-Signature
                                                        class="ml-4 mb-1"
                                                        height="150px"
                                                        v-model="item.proofOfRelease" />
                                                    <span v-if="item.releasedOn != null" class="ml-4 text-caption">
                                                        <span>By</span>
                                                        <BT-Entity
                                                            alternateText=" unknown "
                                                            navigation="public-users"
                                                            inline
                                                            :itemValue="item.releasedByUserID"
                                                            single
                                                            textFilter="toUserLine"
                                                            useLocalCache />
                                                        <span>on {{ item.releasedOn | toShortDateAndTime }}</span>
                                                    </span>
                                                </v-col>

                                                <v-col cols="12" sm="4">
                                                    <v-divider class="my-2" />
                                                    <v-subheader>
                                                        <v-icon left small>mdi-draw</v-icon>
                                                        Proof Of Delivery
                                                        <v-spacer />
                                                        <span>Signer: {{ item.receiverName || '(Unknown)' }}</span>
                                                    </v-subheader>
                                                    <BT-Signature
                                                        class="ml-4 mb-1"
                                                        height="150px"
                                                        v-model="item.proofOfDelivery" />
                                                    <span v-if="item.deliveredOn != null" class="ml-4 text-caption">
                                                        <span>By</span>
                                                        <BT-Entity
                                                            alternateText=" unknown "
                                                            navigation="public-users"
                                                            inline
                                                            :itemValue="item.deliveredByUserID"
                                                            single
                                                            textFilter="toUserLine"
                                                            useLocalCache />
                                                        <span>on {{ item.deliveredOn | toShortDateAndTime }}</span>
                                                    </span>
                                                </v-col>

                                                <v-col cols="12" sm="4">
                                                    <v-divider class="my-2" />
                                                    <v-subheader>
                                                        <v-icon left small>mdi-camera</v-icon>
                                                        Photo
                                                        <v-spacer />
                                                        <BT-Btn
                                                            v-if="item.lat != null && item.lng != null"
                                                            :href="`https://maps.google.com/?q=${item.lat},${item.lng}`"
                                                            inline
                                                            label="Check Map"
                                                            leftIcon="mdi-map-marker-radius"
                                                            small
                                                            target="_blank" />
                                                    </v-subheader>
                                                    <BT-Photo
                                                        height="150px"
                                                        :icon="null"
                                                        :id="item.id" />
                                                </v-col>
                                            </v-row>
                                        </v-card>
                                    </v-list>
                                </template>
                            </BT-Blade-Items>
                        </template>
                    </BT-Settings-Slider>
                </v-col>
            </v-row>
            
            <BT-Snack v-model="msg" />
        </template>
    </BT-Blade-Item>
</template>

<script>
import { firstBy } from 'thenby';
import { getImageData } from '~helpers';
// import axios from 'axios';
// import { BlobServiceClient } from '@azure/storage-blob';

export default {
    name: 'Customer-Invoice-Blade',
    components: {
        // BTDialog: () => import('~components/BT-Dialog.vue'),
        BTMenu: () => import('~components/BT-Menu.vue'),
        BTPhoto: () => import('~components/BT-Photo.vue'),
        // BTSignatureOverlay: () => import('~components/BT-Signature-Overlay.vue'),
        BTSingleActionDialog: () => import('~components/BT-Single-Action-Dialog.vue'),
        BTSidebarExternalLinks: () => import('~components/BT-Sidebar-External-Links.vue'),
        // BTSidebarItems: () => import('~components/BT-Sidebar-Items.vue'),
        BTPrint: () => import('~components/BT-Print.vue'),
        BTSignature: () => import('~components/BT-Signature.vue'),
        BTTable: () => import('~components/BT-Table.vue')
    },
    data: function() {
        return {
            amountPaid: { v: 0 },
            msg: null,
            panelI: null,
            panelV: null,
            showPODToggle: false,
            testData: { data: null }
        }
    },
    props: {
        bladesData: null
    },
    methods: {
        paid(item) {
            item.amountPaid = this.amountPaid.v;
        },
        prepItem(item) {
            item.lineItems = item.lineItems.orderBy('sortNumber');
        },
        
        pullCourierOrders(list) {
            var r = list.sort(firstBy(x => x.fulfilledOn));
            return r;
        },
        pullDeliveries(list) {
            var r = list.sort(firstBy(x => x.deliveredOn));
            return r;
        },
        async pullForPrint(item, id) {
            var uri = this.companyLogoURL(item.sellerID);
            var logoImgData = await getImageData(uri, false);
            
            return {
                delivery: null, //del.length > 0 ? del[del.length - 1] : null,
                id: id,
                invoice: item,
                logoImageData: logoImgData
            }
        },
        async pullForProof(item, id) {
            var del = null;
            
            try {
                del = await this.$BlitzIt.store.get('delivery-archives', null, { id: item.id }, true, null, '/get/ByConsignmentID');
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }

            var uri = this.companyLogoURL(item.sellerID);
            var logoImgData = await getImageData(uri, false);

            var r = {
                delivery: del.length > 0 ? del[del.length - 1] : null,
                id: id,
                invoice: item,
                logoImageData: logoImgData
            };

            return r;
        },
        pullInvoice(item) {
            item.lineItems.sort(firstBy(x => x.sortNumber));
            return item;
        }
    }
}
</script>